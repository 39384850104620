.celebrations-title
{
  color: green;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.celebrations-subtitle
{
  /* color: #13505b; */
  color: black;
  text-align: center;
  font-size: 16px;
}

@media  (min-width: 992px)  
{
    .height-cele
    {
        height: 300px;
    }
}