table,th,td
{
  border: 1px solid black;
}

.english-title
{
  color: green;
  font-weight: bold;
  margin-bottom: 6px;
}

.english-subtitle
{
  /* color: #13505b; */
  color: #0c7489;
  font-size: 16px;
  font-weight: bold;
}