@media all and (max-width:350px) 
{
    .menus
    {
        gap: 11px !important;
    }
}

.menus
{
    /* background: #B9E9C5; */
    background-color: #119da4;
    /* background-color: #0c7489; */
    /* background-color: #13505b; */
}

.navlink
{
    font-weight: bold;
    color:  white !important;
    text-decoration: none;
    border-radius: 3px;
    padding: 3px;
}