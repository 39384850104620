.house-color
{
  color: #119da4;
  font-size: 16px;
  font-weight: bold;
}

@media (min-width: 992px) 
{
  .house-img
  {
    height: 350px;
  }
}