table,th,td
{
  border: 1px solid black;
}

.tamil-title
{
  color: green;
  font-weight: bold;
}

@media (min-width: 992px)
{
  .teacher-img
  {
   height: 300px;
  }
}