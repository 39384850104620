.header-logo1
{
    height: 110px;
    width: 100px;
}

.header-logo2
{
    height: 100px;
    width: 100px;
}

.header-title1
{
    color: #1c2a57;
    margin-bottom: 0;
    font-size: 25px;
    font-weight: 900;
}

.header-title2
{
    color: #068EE0;
    margin-bottom: 0;
    font-size: 25px;
    font-weight: 900;
}

.header-subtitle
{
    font-weight: 600;
}

@media (max-width: 1100px) 
{
    .english-btn,.tamil-btn
    {
        display: none !important;
    }
}

.tamil-btn
{
    color: #1c2a57;
    border: 2px solid #1c2a57;
}

.tamil-btn:hover,.tamil-btn:active
{
    background-color: #1c2a57;
    color: white;
}

.english-btn
{
    color: #068EE0;
    border: 2px solid #068EE0;
}

.english-btn:hover,.english-btn:active
{
    background-color: #068EE0;
    color: white;
}

@media (max-width: 500px) 
{
    .header-title1 ,.header-title2
    {
        font-weight: 800;
        font-size: 20px;
    }
}

@media (max-width: 360px) 
{
    .header-title1 ,.header-title2
    {
        font-size: 16px;
    }
    .header-subtitle
    {
        font-size: 12px;
        font-weight: 600;
    }
    .medium
    {
        font-size: 12px;
    }
    .header-logo1 , .header-logo2
    {
        height: 80px;
        width: 80px;
    }
}