/* ContactForm.css */
.contact-bg
{
    max-width: 400px;
}

form
{
    max-width: 600px;
}

.input-box .field
{
    width: 100%;
    height: 50px;
    background: transparent;
}

.input-box .mess
{
    height: 200px;
    resize: none;
}

.standard-select
{
    height: 50px;
}

@media all  and (max-width: 480px)
{
    form .btn-common
    {
        padding: 10px 90px !important;
    }
}

@media all and (max-width:465px)
{
    .contact
    {
        margin-left: 0px !important;
    }
}
/* Location.css */
@media all and (max-width:390px)
{
    .email,.text-mobile
    {
       font-size: 12px !important;
    }
}

.email:hover
{
    text-decoration: underline !important;
    color: green !important;
}

