.common-bg
{
  background-color: #F7F7F4;
}

.title
{
    font-weight: 900;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
}