.extra-title
{
  color: green;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.extra-img
{
    height: 400px;
}

@media all and (max-width: 992px)
{
    .extra-img
    {
        height: 300px;
    }
}

@media all and (max-width: 768px)
{
    .extra-img
    {
        height: 250px;
    }
}