.beyond-img
{
    height: 200px;
}

@media (max-width:700px) 
{
    .beyond-img
    {
        height: 150px;
    }
}

@media (max-width:450px) 
{
    .beyond-img
    {
        height: 100px;
    }
}